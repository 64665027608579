* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*font-family: "Inter";*/
}

#root {
  min-height: 100vh;
}

button {
  border-radius: 9999px !important;
}

/* input[type="date"]::-webkit-inner-spin-button, */

input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  background-image: url(./assets/calender.png);
  width: 23px;
  height: 23px;
  /* border-width: thin; */
}
